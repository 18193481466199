<template>
  <index-content>
    <div v-if="hasRW" slot="options">
      <div class="n-button-box">
        <nb-sort v-model="sortable" />
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <th class="id">{{ $t('label.id') }}</th>
          <th class="image">{{ $t('label.image') }}</th>
          <th class="name">{{ $t('label.code') }}</th>
          <th class="name">{{ $t('label.name') }}</th>
          <th class="type">{{ $t('label.type') }}</th>
          <th class="unit">{{ $t('label.unit') }}</th>
          <th class="scale text-right">{{ $t('label.scale') }}</th>
          <th class="scale text-right">{{ $t('label.rate') }}</th>
          <th class="n-table-timestamp">{{ $t('label.lastUpdate') }}</th>
          <th v-table-action class="action-edit">{{ $t('label.action') }}</th>
        </thead>
        <n-draggable v-model="records" :disabled="!sortable" @on-change="onDropped">
          <tr v-for="row in records" :key="row.id" :class="{ 'n-table-sortable': sortable }">
            <td>{{ row.id }}</td>
            <td><n-image :image-id="row.image" :type="ImageType.CURRENCY" :title="row.name" :width="22" /></td>
            <td>{{ row.code }}</td>
            <td>{{ row.name }}</td>
            <td v-enums:CurrencyType="row.type"></td>
            <td>{{ row.unit | hyphen }}</td>
            <td class="text-right pr-2">{{ row.scale }}</td>
            <td class="text-right pr-2">{{ row.rate }}</td>
            <td>{{ row.updateDatetime | datetime }}</td>
            <td class="n-button-box">
              <nb-modal @on-click="onBtnShowModal(row)"></nb-modal>
            </td>
          </tr>
        </n-draggable>
      </table>
    </div>
  </index-content>
</template>

<script>
import BaseMarketView from './view';
import DndMixin from '@/mixins/dnd-mixin';
import { ImageType } from '@/constant/enums';
import ModalCurrency from './ModalCurrency';
import { fetch, sort } from '@/api/market/market-currency';

export default BaseMarketView.extend({
  name: 'MarketCurrency',
  mixins: [DndMixin],

  data() {
    return {
      ImageType,
      preload: true,
      sortable: false,
    };
  },

  computed: {},

  methods: {
    doLoad() {
      return fetch();
    },

    parse(r) {
      this.records = r;
    },

    onBtnShowModal(row) {
      this.createModal(ModalCurrency, { model: row, on: this });
    },

    doDrop(list) {
      return sort({ currencyIds: list.map(r => r.id).join(',') });
    },
  },
});
</script>
