<script>
import ResultMixin from './result-mixin';
import { SUCCESS_MUTE } from '@/constant/config';

export default {
  name: 'DnDMixin',
  mixins: [ResultMixin],

  methods: {
    onDropped(list) {
      this.doDrop(list).then(r => {
        if (this.isSuccess(r)) {
          if (!SUCCESS_MUTE) {
            this.$Message.success();
          }
        } else {
          this.showErrorMsg(r);
        }
        this.load();
      });
    },
  },
};
</script>
